import { Size, Step, StepType } from '@arcadehq/shared/types'
import { captureException } from '@sentry/nextjs'
import { decode } from 'blurhash'
import { contrastColor } from 'contrast-color'
import memoize from 'lodash/memoize'

import {
  BROWSER_BAR_OR_CAPTION_HEIGHT,
  defaultUserPrefs,
  FirebaseConfig,
  MOBILE_BREAKPOINT,
  USE_FIREBASE_EMULATOR,
} from '../constants'
import { Timestamp } from './store/firebase'
import { Flow, Team, User, UserProfile } from './types'
import { getStepPreviewUrl } from './utils/steps'

export const isBrowser =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined'

export function widthAsPercentage(
  width: number,
  canvas: { height: number; width: number }
) {
  return width / (canvas.width ?? 1)
}

export function heightAsPercentage(
  height: number,
  canvas: { height: number; width: number }
) {
  return height / (canvas.height ?? 1)
}

export function percentageAsWidth(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.width ?? 1) * percentage
}

export function percentageAsHeight(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.height ?? 1) * percentage
}

const toBase64 = (str: string) =>
  !isBrowser ? Buffer.from(str).toString('base64') : window.btoa(str)

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#ccc" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

export const shimmerDataUrl = (h: number, w: number) =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`

export const blurCache: { [key: string]: string } = {}

export const getBlurDataUrlFromBlurhash = (
  blurhash?: string,
  width = 32,
  height = 32
) => {
  width = Math.round(width)
  height = Math.round(height)

  // server-size rendering fallback
  if (typeof document === 'undefined') {
    return shimmerDataUrl(width, height)
  }
  const cacheKey = `${blurhash}-${width}-${height}`

  if (blurCache[cacheKey]) {
    return blurCache[cacheKey]
  }

  let blurDataUrl = ''

  if (blurhash) {
    const pixels = decode(blurhash, width, height)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    try {
      const imgData = ctx!.createImageData(width, height)
      imgData.data.set(pixels)
      ctx!.putImageData(imgData, 0, 0)
      blurDataUrl = canvas.toDataURL()
    } catch (error) {
      // ignored because we use the shimmer data below instead
    }
  }

  if (!blurDataUrl) {
    blurDataUrl = shimmerDataUrl(width, height)
  }

  blurCache[cacheKey] = blurDataUrl
  return blurDataUrl
}

export const interpolate = (begin: number, end: number, curr: number) =>
  begin + (curr / 99) * (end - begin)

export const getFirstStepWithSize = (steps: Step[]) => {
  return steps.find(step =>
    [StepType.Video, StepType.Image].includes(step.type)
  )
}

export const getHeightAndWidthOfStep = (
  step: Step,
  cb: (dim: Size) => void
) => {
  if (
    step.type === StepType.Image ||
    (step.type === StepType.Video && step.videoThumbnailUrl)
  ) {
    if (
      step.url.startsWith('https://cdn.arcade.software') &&
      step.type === StepType.Image
    ) {
      const attrUrl = `/api/images/attributes/${
        step.url.split('?')[0].split('/').slice(-1)[0]
      }`
      fetch(attrUrl)
        .then(attrs => {
          if (attrs.ok) {
            attrs.json().then(({ width, height }) => cb({ width, height }))
          }
        })
        .catch(err => captureException(err))
    } else {
      const img: HTMLImageElement = document.createElement('img')
      img.onload = () => {
        if (img) {
          cb({ height: img.height, width: img.width })
          img.src = ''
        }
      }

      // we only need a blurry image to get the ratio
      img.src = optimizedImageUrl(getStepPreviewUrl(step), 'q=0')
    }
  } else if (step?.type === StepType.Video && !step.videoThumbnailUrl) {
    // Left here for backwards compatibility for video steps without a `videoThumbnailUrl`
    let vid: HTMLVideoElement | null = document.createElement('video')
    vid.autoplay = false
    vid.onloadedmetadata = () => {
      if (!vid) return
      cb({ height: vid.videoHeight, width: vid.videoWidth })
      vid.src = ''
      vid = null
    }
    vid.src = step.url
  } else {
    captureException(new Error('Unhandled ratio'), { extra: { step } })
  }
}

export const concatUnique = <T>(array: T[], newElement: T): T[] => {
  const set = new Set(array)
  set.add(newElement)
  return Array.from(set)
}

export const selectElementContents = (el: HTMLElement) => {
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel?.removeAllRanges()
  sel?.addRange(range)
}

export function getPositionAndBounds(
  x: number,
  y: number,
  stageDimensions: { height: number; width: number },
  containerDimensions: { top: number; left: number }
) {
  const topBound = 10
  const leftBound = 10
  const rightBound = stageDimensions.width - 10
  const bottomBound = stageDimensions.height - 10
  const top = Math.min(
    Math.max(y - containerDimensions.top - 2, topBound),
    bottomBound
  )
  const left = Math.min(
    Math.max(x - containerDimensions.left - 2, leftBound),
    rightBound
  )
  return { x: left, y: top, topBound, leftBound, rightBound, bottomBound }
}

export const mouseIsAlmostWithinElement = (
  event: React.MouseEvent,
  element: Element,
  padding: number
) => {
  const rect = element.getBoundingClientRect()
  return (
    event.clientX >= rect.x - padding &&
    event.clientX <= rect.x + rect.width + padding &&
    event.clientY >= rect.y - padding &&
    event.clientY <= rect.y + rect.height + padding
  )
}

export function optimizedImageUrl(url: string, filterOpts?: string) {
  if (!filterOpts) {
    filterOpts = 'fit=scale-down,f=auto'
    if (!isBrowser || !window?.innerWidth) {
      filterOpts = 'fit=scale-down,f=auto,width=1920,q=75'
    } else {
      const zoomRatio =
        (1 -
          //@ts-ignore
          Math.abs(window.innerWidth - 2000) /
            //@ts-ignore
            Math.max(window.innerWidth, 2000)) *
        100
      filterOpts = `${filterOpts},width=${window.innerWidth},q=${interpolate(
        60,
        75,
        zoomRatio
      )}`
      if (window.devicePixelRatio) {
        filterOpts = `${filterOpts},dpr=${window.devicePixelRatio}`
      }
    }
  }

  // This uses Cloudfront's image optimization service
  // https://developers.cloudflare.com/image-resizing/url-format
  return url.replace(
    'cdn.arcade.software/',
    `cdn.arcade.software/cdn-cgi/image/${filterOpts}/`
  )
}

export const integerOrDefault = (
  input: string | null,
  defaultTo = 0
): number => {
  if (input === null) return defaultTo
  return parseInt(input)
}

export const quotientOrNull = (
  numerator: string | number | null,
  denominator: string | number | null
): number | null => {
  if (numerator === '0' || numerator === 0) {
    return 0
  }
  if (denominator === '0' || denominator === 0 || denominator === null) {
    return null
  }
  if (numerator === null) {
    return 0
  }
  return +numerator / +denominator
}

export function isPointingToProduction() {
  return FirebaseConfig.projectId === 'arcade-cf7d5'
}

export function isProductionEnv() {
  return (
    process.env.VERCEL_ENV === 'production' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  )
}

export function isPreviewEnv() {
  return (
    process.env.VERCEL_ENV === 'preview' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  )
}

export function isStagingEnv() {
  return process.env.STAGING || process.env.NEXT_PUBLIC_STAGING
}

export function isLocalEnv() {
  return (
    USE_FIREBASE_EMULATOR ||
    (!isProductionEnv() && !isPreviewEnv() && !isStagingEnv())
  )
}

export function getBaseUrl(forDemoHost = false) {
  if (isStagingEnv()) {
    return 'https://staging.arcade.software'
  }

  if (isProductionEnv() && !forDemoHost) {
    return 'https://app.arcade.software'
  }

  if (isProductionEnv() && forDemoHost) {
    return 'https://demo.arcade.software'
  }

  if (isPreviewEnv()) {
    return `https://${
      process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
    }`
  }

  return 'http://localhost:3000'
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function isMobileOrSmallScreen(aspectRatio?: number, el?: HTMLElement) {
  const width = el?.clientWidth ?? window.innerWidth
  const height = el?.clientHeight ?? window.innerHeight
  return (
    isMobile() ||
    Math.max(width, aspectRatio ? width * aspectRatio : height) <
      MOBILE_BREAKPOINT
  )
}

export function hexColorRegex() {
  return /#([a-f0-9]{3}|[a-f0-9]{6}|[a-f0-9]{8})\b/gi
}

export const safeInvertColor = memoize((color: string): string => {
  try {
    return contrastColor({ bgColor: color }).toLowerCase()
  } catch (e) {
    return '#ffffff'
  }
})

export function isCurrentCustomer(
  customer: User | UserProfile | Team | undefined
) {
  return customer?.customerId && customer?.currentSubscriber
}

export class HistoryStack<T> {
  protected stack: T[]
  protected currIdx: number

  constructor() {
    this.stack = []
    this.currIdx = -1
  }

  add(item: T) {
    if (item === this.stack[this.stack.length - 1]) return
    this.stack = [...this.stack.slice(0, this.currIdx + 1), item]
    this.currIdx = this.stack.length - 1
  }

  getAll() {
    return this.stack
  }

  getCurrent() {
    return this.stack[this.currIdx]
  }

  hasPrevious() {
    return this.currIdx > 0
  }

  hasNext() {
    return this.currIdx < this.stack.length - 1
  }

  getPrevious() {
    this.currIdx = this.currIdx >= 0 ? this.currIdx - 1 : -1
    return this.currIdx === -1 ? null : this.stack[this.currIdx]
  }

  getNext() {
    this.currIdx =
      this.currIdx + 1 < this.stack.length
        ? this.currIdx + 1
        : this.stack.length
    return this.currIdx === this.stack.length ? null : this.stack[this.currIdx]
  }
}

export function getEmbedCode(flow: Flow) {
  const embedUrl = `${getBaseUrl(true)}/${flow.id}?embed`

  const paddingBottom = `calc(${
    flow.aspectRatio * 100
  }% + ${BROWSER_BAR_OR_CAPTION_HEIGHT}px)`

  return `<div style="position: relative; padding-bottom: ${paddingBottom}; height: 0;"><iframe src="${embedUrl}" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;" title="${flow.name}"></iframe></div>`
}

export async function downloadFile(
  url: string,
  filename: string,
  fallbackUrl?: string
) {
  if (!isBrowser) return

  const downloadUrl = (href: string) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = href
    a.target = '_blank'
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  try {
    const res = await fetch(url)
    const blob = await res.blob()
    const objectUrl = URL.createObjectURL(blob)
    downloadUrl(objectUrl)
    URL.revokeObjectURL(url)
  } catch (_: any) {
    // will fail if cors is not handled
    // then we fallback to direct download
    // (which won't work in a cross origin context)
    downloadUrl(fallbackUrl ?? url)
  }

  return true
}

export const getOverlayColors = (flow: Flow | undefined) => {
  if (flow) {
    for (const step of flow.steps) {
      for (const hotspot of step.hotspots) {
        if (hotspot && hotspot.bgColor && hotspot.textColor) {
          return {
            buttonBgColor: hotspot.bgColor,
            buttonFgColor: hotspot.textColor,
          }
        }
      }
    }
  }
  return {
    buttonBgColor: defaultUserPrefs.bgColor,
    buttonFgColor: defaultUserPrefs.textColor,
  }
}

export const getInitials = (name: string | null) => {
  if (!name) {
    return 'A'
  }

  const names = name.toUpperCase().split(' ')
  if (names.length === 1) {
    return names[0].charAt(0)
  }
  return names[0].charAt(0) + names[names.length - 1].charAt(0)
}

export const mod = (input: number, modulus: number) => {
  return ((input % modulus) + modulus) % modulus
}

export const normalizeDate = (date: string | Date | Timestamp) => {
  if (typeof date === 'object' && 'seconds' in date) {
    return new Date(date.seconds * 1000)
  }
  return new Date(date)
}

export const getCdnUrl = (url: string) => {
  if (url.startsWith('https://firebasestorage') && isPointingToProduction()) {
    return url
      .replace(
        'firebasestorage.googleapis.com/v0/b/arcade-cf7d5.appspot.com/o',
        'cdn.arcade.software'
      )
      .replace(
        'firebasestorage.googleapis.com/v0/b/cdn.arcade.software/o',
        'cdn.arcade.software'
      )
      .replace('%2F', '/')
      .split('?')[0]
  }
  return url
}

export const asBooleanDict = (ids: string[]): { [id: string]: true } =>
  ids.reduce((dict, id) => ({ ...dict, [id]: true }), {})

export const arrayOfTrueKeys = (dict: { [id: string]: boolean }) =>
  Object.entries(dict)
    .filter(([, value]) => !!value)
    .map(([key]) => key)

export const alphabeticalComparator = <T extends { name: string }>(
  a: T,
  b: T
) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  if (nameA === nameB) return 0
  return nameA > nameB ? 1 : -1
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const roundToPlaces = (num: number, precision: number) => {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor
}

export const isValidHttpsUrl = (str: string) => {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'https:'
}

export function isMacOS(): boolean {
  if (typeof navigator !== 'undefined') {
    const platform =
      // @ts-ignore
      navigator?.userAgentData?.platform ?? navigator?.platform
    return !!platform?.toLowerCase().startsWith('mac')
  }
  return false
}

export const formatCurrency = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: num % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(num)
}
